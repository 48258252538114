import React, { useState, useEffect } from 'react';
import {
    Typography,
    Box,
    Container,
    TableContainer
    ,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Button,
    Select,
    MenuItem,
    Snackbar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';

import * as s from '../../shared/sharedSelectors';
import * as lookupSelectors from '../../lookups/lookupSelectors';
import * as selectors from '../accountSelectors';
import * as errorSelectors from "../../shared/errors/errorSelectors";
import { switchNavMenu, getOrganizationPlan } from '../../shared/sharedActions';
import { loadSubscriptionPlans } from '../../lookups/lookupActions';
import {
    addCreditCard,
    updateCreditCard,
    getCreditCardInfo,
    updateSubscription,
    setSuccessfulPaymentFlag,
    deactivateSubscription
    ,
    setUpdateSubscriptionFlag,
    setDeactivateSubscriptionFlag,
    submitCouponCode,
    loadCouponCodes
} from '../accountActions';
import { ApplicationState } from "../../ApplicationState";
import { OrganizationModel, SubscriptionPlanModel } from '../../shared/SharedModels';

import './accountPage.scss';
import SectionComponent from '../../shared/components/SectionComponent';
import CreditCardContainer from './CreditCardContainer';
import { ValidationError } from '../../shared/errors/ErrorModels';
import { CreditCardModel, CouponCodesModel } from '../AccountModels';
import { Alert } from '@material-ui/lab';

type SubscriptionProps = {
    navTabIsSelected: boolean,
    subscriptionPlans: SubscriptionPlanModel[],
    // selectedPlan: SubscriptionPlanModel | undefined,
    creditCards: CreditCardModel[] | [],
    validationErrors: ValidationError[] | [],
    isSuccessfulPayment: boolean,
    isSubscriptionUpdated: boolean,
    isSubscriptionDeactivated: boolean,
    couponCodes: CouponCodesModel[],
    isPrimaryAccount: boolean

    org: OrganizationModel,

    switchNavMenu: typeof switchNavMenu,
    loadSubscriptionPlans: typeof loadSubscriptionPlans,
    getOrganizationPlan: typeof getOrganizationPlan,
    addCreditCard: typeof addCreditCard,
    updateCreditCard: typeof updateCreditCard,
    getCreditCardInfo: typeof getCreditCardInfo,
    updateSubscription: typeof updateSubscription,
    setSuccessfulPaymentFlag: typeof setSuccessfulPaymentFlag,
    deactivateSubscription: typeof deactivateSubscription,
    loadCouponCodes: typeof loadCouponCodes

    submitCouponCode: typeof submitCouponCode

    primaryOrgId: number
}

const $ = require('jquery');
$.DataTable = require('datatables.net');
require('datatables.net-responsive');
require('datatables.net-fixedheader');
require('datatables.net-dt/css/jquery.dataTables.css');
require('datatables.net-responsive-dt/css/responsive.dataTables.css');

const useStyles = makeStyles({
    table: {
      minWidth: 650,

      borderBottomLeftRadius: '5px',
      borderBottomRightRadius: '5px',
    },
    cell: {
        borderRight: '1px solid rgba(224, 224, 224, 1)',
        width: "150px",
    },
    lookup: {
        borderBottom: "none",
    },
    inputRoot: {
        "& .MuiInput-underline:before": {
          borderBottom: "none"
        },
        "& .MuiInput-underline:after": {
            borderBottom: "none"
        },
        "& .MuiInputBase-input": {
            backgroundColor: "white",
        },
    },
    normalText: {
        fontWeight: "normal",
    },
    successTitle: {
        fontSize: "xx-large",
        fontWeight: "bold",
        color: "#69CC3D",
    },
    zeroMargin: {
        margin: 0,
    },
});

type UpdateSubscriptionPopupProps = {
    open: boolean,
    stateFlag: boolean,
    handleUpdate: () => void,
    handleClose: () => void,
};

type DeactivateSubscriptionPopupProps = {
    open: boolean,
    stateFlag: boolean,
    handleDeactivate: () => void,
    handleClose: () => void,
};

const UpdateSubscriptionPopup: React.FC<UpdateSubscriptionPopupProps> = (props) => {
    const classes = useStyles();
    const { open, stateFlag } = props;

    return (
        <Dialog
            open={open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to Update your Subscription?</DialogTitle>
            <DialogContent>
                <Typography>
                    {!stateFlag ?
                        <Typography>
                            You will get billed immediately for the difference in Subscription when upgrading.
                        </Typography>
                        :
                        <Typography className={classes.successTitle}>
                            Success!
                        </Typography>
                    }
                </Typography>
            </DialogContent>
            <DialogActions>
                {
                    !stateFlag ?
                        <>
                            <Button onClick={props.handleClose}>Cancel</Button>
                            <Button color="secondary" onClick={props.handleUpdate}>Yes, Update</Button>
                        </>
                    :
                        <Button onClick={props.handleClose}>Close</Button>
                }
            </DialogActions>
        </Dialog>
    )
}

const DeactivateSubscriptionPopup: React.FC<DeactivateSubscriptionPopupProps> = (props) => {
    const classes = useStyles();
    const { open, stateFlag } = props;

    return (
        <Dialog
            open={open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Are you sure you want to Deactivate your Subscription?</DialogTitle>
            <DialogContent>
                {!stateFlag ?
                    <Typography>
                        This will stop your billing, as well as survey submissions. Your data will be automatically deleted after 60 days.
                    </Typography>
                    : <Typography className={classes.successTitle}>
                        SUCCESS!
                      </Typography>
                }
            </DialogContent>
            <DialogActions>
                {
                    !stateFlag ?
                        <>
                            <Button onClick={props.handleClose}>Cancel</Button>
                            <Button color="secondary" onClick={props.handleDeactivate}>Yes, Deactivate</Button>
                        </>
                    :
                        <Button onClick={props.handleClose}>Close</Button>
                }
            </DialogActions>
        </Dialog>
    )
}

let countRow = 0;
const Subscription = (props: SubscriptionProps) => {
    const classes = useStyles();
    const { org, subscriptionPlans, validationErrors, isSuccessfulPayment, isSubscriptionUpdated, isSubscriptionDeactivated, couponCodes, isPrimaryAccount } = props;

    const [selectedPlanId, setSelectedPlanId] = useState<number>(org.subscriptionPlanId);

    const selectedPlan = subscriptionPlans.find((plan: SubscriptionPlanModel) => plan.planId === selectedPlanId);
    const defaultCC = props.creditCards.length ? props.creditCards.find((card: CreditCardModel) => card.isDefault) || null : null;

    const [updateSubscriptionPopup, setUpdateSubscriptionPopup] = useState<boolean>(false);
    const [deactivateSubscriptionPopup, setDeactivateSubscriptionPopup] = useState<boolean>(false);

    useEffect(() => {
        props.loadCouponCodes();

        props.loadSubscriptionPlans();

        // if(!selectedPlan) props.getOrganizationPlan(props.org.subscriptionPlanId);

        if(!props.creditCards.length) props.getCreditCardInfo();
    }, []);

    const handlePlanChanged = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        setSelectedPlanId(event.target.value);
    }

    const handleUpdateSubscription = () => {

        props.updateSubscription(selectedPlanId);
    };

    const handleDeactivateSubscription = () => {
        props.deactivateSubscription();
    };

    const handleOpenUpdateSubscriptionPopup = () => {
        setUpdateSubscriptionPopup(true);
    };

    const handleOpenDeactivateSubscriptionPopup = () => {
        setDeactivateSubscriptionPopup(true);
    };

    const handleCloseUpdateSubscriptionPopup = () => {
        setUpdateSubscriptionPopup(false);
    };

    const handleCloseDeactivateSubscriptionPopup = () => {
        setDeactivateSubscriptionPopup(false);
    };

    const handleAlertClose = () => {
        props.setSuccessfulPaymentFlag(false);
    }

    const initialInputs = {
        couponCode: "",
        organizationId: org.id
    };

    const [couponInputs, setInputs] = useState(initialInputs);

    const handleCouponCodeChange = (event: React.ChangeEvent<{ name?: string; value: any; }>) => {

        setInputs(prev => {
            return {
                ...prev,
                [event.target.name!]: event.target.value,
            }
        })
    };

    const submitCouponCodeClick = () => {
        if (couponInputs.couponCode === "") {
            alert("Coupon Code Field Is Empty.");
        } else {
            props.submitCouponCode({
                couponCode: couponInputs.couponCode,
                organizationId: org.id
            });
        }
    };

    const updateCount = () => {
        if (countRow === couponCodes.length){
            setTimeout(function(){$('#couponPromotionsTable').DataTable({responsive:false})},500);
        }
    }

    const isDisabledPlan = (plan: SubscriptionPlanModel) => {
        return plan.priceTotal != 0 && plan.discountPercent != 100;
    }

    return (
        <Box id='subscription-container'>
            <Box className="subscription">
                <Box className='title'>
                    <Typography
                        variant='h6'
                        className={classes.zeroMargin}
                    >
                        Select Your Plan
                    </Typography>
                </Box>

                { subscriptionPlans.length > 0 && selectedPlan &&
                    <TableContainer>
                        <Table className={`${classes.table} tbl`} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className={classes.cell} align="center">Staff Members</TableCell>
                                    <TableCell className={classes.cell} align="center">Discount</TableCell>
                                    <TableCell className={classes.cell} align="center">Total</TableCell>
                                    <TableCell align="center">Subscription</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow className="subscriptionplan">
                                    <TableCell component="th" scope="row" classes={{ root: classes.inputRoot }} >
                                        <Select value={selectedPlan!.planId} classes={{ root: classes.inputRoot, select: 'focused' }} onChange={e => handlePlanChanged(e)}>
                                            {props.subscriptionPlans.map((plan: SubscriptionPlanModel) =>
                                                <MenuItem
                                                    key={plan.planId}
                                                    selected classes={{ root: 'MenuItem', selected: 'selected' }}
                                                    value={plan.planId} disabled={(props.primaryOrgId !== props.org.id) && (isPrimaryAccount) ? false : false}>{plan.title}</MenuItem>
                                            )}
                                        </Select>
                                    </TableCell>
                                    <TableCell align="center">
                                            {selectedPlan!.typeName}
                                    </TableCell>
                                    {selectedPlan!.priceTotal > 250
                                        ? <TableCell align="center">∞</TableCell>
                                        : <TableCell align="center">${selectedPlan!.priceTotal.toFixed(2)}</TableCell>
                                    }
                                    <TableCell align="center">
                                            <Button
                                                className="margin-right"
                                                variant="contained"
                                                color="secondary"
                                                disabled={selectedPlanId === org.subscriptionPlanId }
                                                onClick={handleOpenUpdateSubscriptionPopup}
                                            >
                                                Update
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                color="primary"
                                                disabled={selectedPlanId !== org.subscriptionPlanId }
                                                onClick={handleOpenDeactivateSubscriptionPopup}
                                            >
                                                Deactivate
                                            </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                }
            </Box>
            <SectionComponent marginTop="85px">Coupon Code Promotions</SectionComponent>
            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell align="center">
                                <TextField name="couponCode" style={{width:"100%"}} type="text" variant="outlined" onChange={handleCouponCodeChange}/>
                            </TableCell>
                            <TableCell align="left">
                                <Button
                                    variant="contained"
                                    color="secondary" onClick={submitCouponCodeClick}>Submit</Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            <TableContainer>
                <Table id={"couponPromotionsTable"} className={`table table-bordered`} aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Initiative</TableCell>
                            <TableCell>Code</TableCell>
                            <TableCell>Staff Members</TableCell>
                            <TableCell>Discount</TableCell>
                            <TableCell>Expired</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {couponCodes.length > 0 && couponCodes.map(coupon => {
                            countRow++;
                            updateCount();
                            return (
                                <TableRow>
                                    <TableCell align="center">
                                        {coupon.initiative}
                                    </TableCell>
                                    <TableCell align="center">
                                        {coupon.code}
                                    </TableCell>
                                    <TableCell align="center">
                                        {coupon.subscriptionLevel}
                                    </TableCell>
                                    <TableCell align="center">
                                        {coupon.discount}
                                    </TableCell>
                                    <TableCell align="center">
                                        {coupon.isExpired}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <SectionComponent marginTop="85px">ADD A PAYMENT OPTION</SectionComponent>
            <CreditCardContainer
                creditCards={props.creditCards}
                addCreditCard={props.addCreditCard}
                updateCreditCard={props.updateCreditCard}
                validationErrors={validationErrors}
            />

            <Snackbar open={isSuccessfulPayment} onClose={handleAlertClose} autoHideDuration={1000} >
                <Alert variant="filled" severity="success">
                    Payment is successful
                </Alert>
            </Snackbar>

            <UpdateSubscriptionPopup
                handleUpdate={handleUpdateSubscription}
                handleClose={handleCloseUpdateSubscriptionPopup}
                open={updateSubscriptionPopup}
                stateFlag ={isSubscriptionUpdated}
            />
            <DeactivateSubscriptionPopup
                handleDeactivate={handleDeactivateSubscription}
                handleClose={handleCloseDeactivateSubscriptionPopup}
                open={deactivateSubscriptionPopup}
                stateFlag ={isSubscriptionDeactivated}
            />
        </Box>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        navTabIsSelected: s.navTabSelector(state),
        subscriptionPlans: lookupSelectors.subscriptionPlansSelector(state),
        validationErrors: errorSelectors.validationSelector(state),

        creditCards: selectors.creditCardsSelector(state),
        isSuccessfulPayment: selectors.successfulPaymentFlagSelector(state),
        isSubscriptionUpdated: selectors.subscriptionUpdatedFlagSelector(state),
        isSubscriptionDeactivated: selectors.subscriptionDeactivatedFagSelector(state),
        couponCodes: selectors.couponCodesSelector(state),
        primaryOrgId: selectors.primaryOrgIdSelector(state)
    }
}

const mapDispatchToProps = { switchNavMenu, getOrganizationPlan, loadSubscriptionPlans, addCreditCard, updateCreditCard
    , getCreditCardInfo, updateSubscription, deactivateSubscription, setSuccessfulPaymentFlag, setUpdateSubscriptionFlag, setDeactivateSubscriptionFlag, submitCouponCode,loadCouponCodes
};

const SubscriptionContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Subscription);

export default SubscriptionContainer;
