import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, makeStyles } from "@material-ui/core";
import { useEffect, useState } from "react";
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { RouteComponentProps, withRouter } from 'react-router';

import AccountTabs from './components/AccountTabs';
import BillingInfo from './components/BillingInfo';
import Subscription from './components/Subscription';
import * as sharedSelectors from '../shared/sharedSelectors';
import * as s from './accountSelectors';
import { switchNavMenu } from '../shared/sharedActions';
import { ApplicationState } from "../ApplicationState";
import { BillingInfoModel, InviteAdministratorRequestModel } from "./AccountModels";
import { getBillingInfo, updateBillingInfo, inviteUser, setInviteUserFlag } from './accountActions';
import { redirect } from '../shared/router/routerActions';
import { UserProfileModel } from "../shared/SharedModels";
import SectionComponent from "../shared/components/SectionComponent";

import { validationSelector } from "../shared/errors/errorSelectors";

import './components/accountPage.scss';
import { ValidationError } from "../shared/errors/ErrorModels";
import Invoices from "./components/Invoices";
import Administrators from "./components/Administrators";

const useStyles = makeStyles({
    successTitle: {
        fontSize: "xx-large",
        fontWeight: "bold",
        color: "#69CC3D",
    },
    hiddenElement: {
        display: "none",
    }
});

type AccountProps = {
    navTabIsSelected: boolean,
    profile: UserProfileModel | undefined,
    billingInfo: BillingInfoModel | undefined,
    validationErrors: ValidationError[],
    isDisabledService: boolean,
    isUserInvited: boolean,

    getBillingInfo: (orgId: number) => void;
    updateBillingInfo: typeof updateBillingInfo,
    switchNavMenu: typeof switchNavMenu;
    redirect: typeof redirect;
    inviteUser: typeof inviteUser,
    setInviteUserFlag: typeof setInviteUserFlag,
    isPrimaryAccount: boolean
}

type InviteUserPopupProps = {
    open: boolean,
    isUserInvited: boolean,

    handleClose: () => void,
    handleSendInvite: (userInfo: InviteAdministratorRequestModel) => void,
    handleSuccessfulInviteClose: () => void,
};

const InviteUserPopup: React.FC<InviteUserPopupProps> = (props) => {
    const classes = useStyles();
    const { open, isUserInvited } = props;
    const initialAdmin = { fullName: "", email: "", };
    const [admin, setAdmin] = useState<InviteAdministratorRequestModel>(initialAdmin);

    const handleChange = (event: React.ChangeEvent<{ name?: string; value: any }>) => {
        if (!event.target.name) {
            return;
        }

        setAdmin(prev => {
            return {
                ...prev,
                [event.target.name!]: event.target.value,
            }
        });
    };

    const handleSuccessClose = () => {
        props.handleSuccessfulInviteClose();
        setAdmin(initialAdmin);
    };

    return (
        <Dialog
            open={open}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth={true}
            maxWidth="sm"
        >
            <DialogTitle id="alert-dialog-title">
                Invite Administrator
            </DialogTitle>
            <DialogContent>
                {
                    isUserInvited ?
                    <Typography className={classes.successTitle}>
                        Success!
                    </Typography>
                    :
                    <>
                        <TextField name='fullName' value={admin?.fullName} label="Full Name" variant="outlined" margin="dense" onChange={handleChange}/>
                        <TextField name='email' value={admin?.email} label="Default Email Address" variant="outlined" margin="dense" onChange={handleChange} />
                    </>
                }
            </DialogContent>
            <DialogActions>
                {
                    isUserInvited ?
                        <Button onClick={handleSuccessClose}>Close</Button>
                    :
                        <>
                            <Button onClick={props.handleClose}>Cancel</Button>
                            <Button onClick={() => props.handleSendInvite(admin)}>SEND INVITE</Button>
                        </>
                }
            </DialogActions>
        </Dialog>
    )
};

const AccountPage: React.FC<AccountProps & RouteComponentProps<{ }>> = (props) => {
    const { navTabIsSelected, profile, validationErrors, billingInfo, isDisabledService, isUserInvited, location
        , getBillingInfo, updateBillingInfo, switchNavMenu, isPrimaryAccount
    } = props;

    const classes = useStyles();

    const [invitePopupOpen, setInvitePopupOpen] = useState(false);

    useEffect(() => {
        // organize it in right way

       if(navTabIsSelected) {
           switchNavMenu(false);
        }

        // what if billingInfo was changed from other device
        if(!billingInfo) getBillingInfo(profile!.organization.id);
    }, []);

    const handleInvitePopupOpen = () => {
        setInvitePopupOpen(true);
    };

    const handleInvitePopupClose = () => {
        setInvitePopupOpen(false);
    }

    const handleSendInvite = (adminInfo: InviteAdministratorRequestModel) => {
        props.inviteUser(adminInfo);
    };

    const handleSuccessfulInvite = () => {
        handleInvitePopupClose();
        props.setInviteUserFlag(false);
    };

    return (
        <Box id='account-container'>
            <Box display="flex" justifyContent="space-between" marginBottom="10px" >
                <SectionComponent>ACCOUNT</SectionComponent>
                <Button
                    variant="contained"
                    color="secondary"
                    style={{ width: "112px", marginRight: "10px" }}
                    onClick={handleInvitePopupOpen}
                    className={!(/users/.test(location.pathname)) ? classes.hiddenElement : ""}
                >
                    Invite
                </Button>
            </Box>
            <AccountTabs orgId={profile!.organization.id} isDisabledService={isDisabledService} redirect={props.redirect}/>
            <Switch>
                <Route path='/:orgid/account/billinginfo'>
                    {
                        billingInfo &&
                            <BillingInfo
                                org={profile!.organization}
                                validationErrors={validationErrors}

                                updateBillingInfo={updateBillingInfo}
                            />
                    }

                </Route>
                <Route path='/:orgid/account/subscription'>
                    <Subscription org={profile!.organization} isPrimaryAccount={isPrimaryAccount}/>
                </Route>
                <Route path='/:orgid/account/invoices'>
                    <Invoices />
                </Route>
                <Route path='/:orgid/account/users'>
                    <Administrators />
                </Route>
            </Switch>

            <InviteUserPopup
                open={invitePopupOpen}
                handleClose={handleInvitePopupClose}
                handleSendInvite={handleSendInvite}
                isUserInvited={isUserInvited}
                handleSuccessfulInviteClose={handleSuccessfulInvite}
            />
        </Box>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        navTabIsSelected: sharedSelectors.navTabSelector(state),
        profile: sharedSelectors.profileSelector(state),
        billingInfo: s.billingInfoSelector(state),
        validationErrors: validationSelector(state),
        isDisabledService: sharedSelectors.isDisabledServiceSelector(state),
        // errors: selectors.todoListsErrorSelector(errors),
        // ownProps
        isUserInvited: s.userInvitedFlagSelector(state),
    }
}

const mapDispatchToProps = { switchNavMenu, getBillingInfo, updateBillingInfo, redirect, inviteUser, setInviteUserFlag }

const AccountPageContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountPage);

export default withRouter(AccountPageContainer);
