import React, {useEffect, useState} from 'react';
import {Box, Button, makeStyles, Snackbar} from '@material-ui/core';
import {connect} from 'react-redux';

import NavTabs from './NavTabs';
import MenuBar from './MenuBar';
import Loading from '../loading/LoadingStateContainer';
import {getProfile, setReenteringFlag, setServiceIsDisabledFlag, setVerifying} from '../sharedActions';
import {redirect} from "../router/routerActions";
import {OrganizationModel, UserProfileModel, VerifyingModel, WhiteLabelProfile} from '../SharedModels';
import {ApplicationState} from '../../ApplicationState';
import * as sharedSelectors from '../sharedSelectors';
import {Link, Redirect, useHistory} from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import {SubscriptionStatus} from '../enums/SubscriptionStatus';
import {appSettings} from '../../config/appSettings';
import VerifyingPopup from '../../EntryPages/SignUpPage/components/VerifyingPopup';
import {confirmEmailVerification, reenterSignupEmail, resendOTP} from '../../EntryPages/SignUpPage/signUpActions';
import TrialExpiredPopup from "./TrialExpiredPopup";
import Account from "../../AccountPage/AccountPage";
const subscriptionTrialDays = appSettings.constants.subscriptionTrialDays;

type Props = {
    whiteLabel?: WhiteLabelProfile,
    children: React.ReactNode,
    userProfile: UserProfileModel | undefined,
    isDisabledService: boolean,
    verifying: VerifyingModel,

    getProfile: typeof getProfile,
    redirect: typeof redirect,
    setServiceIsDisabledFlag: typeof setServiceIsDisabledFlag,
    confirmEmailVerification: typeof confirmEmailVerification;
    resendOTP: typeof resendOTP,
    setVerifying: typeof setVerifying,
    reenterSignupEmail: typeof reenterSignupEmail,
    setReenteringFlag: typeof setReenteringFlag,
};

const useStyles = makeStyles({
    alertZIndex: {
        zIndex: 1300,
    },
});

interface PrimaryAccount {
    isPrimaryAccount: boolean
}

const AppWrapper: React.FC<Props> = (props) => {
    const history = useHistory();
    const {userProfile, children, isDisabledService, verifying} = props;
    const [agentSelect, setAgentSelect] = useState<boolean>(false);
    const [snackbarContent, setSnackbarContent] = useState<any>(null);

    const getSystemMessage = (org: OrganizationModel) => {
        const snackbar = (children: any) => {
            return (
                <Snackbar
                    className="system-msg"
                    open={true}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    style={{zIndex: 1300}}
                >
                    {/* A custom child element for the Account component is created so that a specific prop can be passed to it */}
                    <>
                        {React.Children.map(children, child => {
                            if (React.isValidElement(child) && child.type === Account) {
                                return React.cloneElement(child as React.ReactElement<PrimaryAccount>, { isPrimaryAccount: agentSelect });
                            }
                            return child;
                        })}
                    </>
                </Snackbar>
            )
        };

        // conditions
        if (!org.isVerified) {
            return (
                snackbar(
                    <Alert
                        action={
                            <Button
                                className="btn-override"
                                color="inherit"
                                size="small"
                            >
                                {`Your organization isn't verified. Check your email please.`}
                            </Button>
                        }
                        severity="info">
                    </Alert>
                )
            )
        } else if (org.subscriptionStatus === SubscriptionStatus.Trial && !props.whiteLabel) {
            return (
                snackbar(
                    <Alert
                        action={
                            <Button
                                className="btn-override"
                                component={Link}
                                to={`/org${org.id}/account/subscription`}
                                color="inherit"
                                size="small"
                            >
                                {`Your ${org.subscriptionTrialDays || subscriptionTrialDays} Day Trial is expiring in ${org.trialDaysLeft} Days. ${agentSelect ? 'CLICK-HERE to upgrade now!' : ''}`}
                            </Button>
                        }
                        severity="info">
                    </Alert>
                )
            );
        } else if (org.subscriptionStatus == SubscriptionStatus.FailedPayment) {

            return (
                snackbar(
                    <Alert
                        action={
                            <Button
                                className="btn-override"
                                component={Link}
                                to={`/org${org.id}/account/subscription`}
                                color="inherit"
                                size="small"
                            >
                                {`IMPORTANT: Your card payment for your Subscription was unsuccessful. CLICK-HERE in order to rectify to avoid service interruption.`}
                            </Button>
                        }
                        severity="error">
                    </Alert>
                )
            )
        } else if (!org.isActive || !org.subscriptionIsActive || org.subscriptionStatus === SubscriptionStatus.Deactivated) {

            return (
                snackbar(
                    <Alert
                        action={
                            <Button
                                className="btn-override"
                                color="inherit"
                                size="small"
                            >
                                {`IMPORTANT: Your service has been Deactivated. Email support@ratemyservice.io in order to resolve this issue.`}
                            </Button>
                        }
                        severity="error">
                    </Alert>
                )
            )
        }
        return false;
    };

    useEffect(() => {
        if (props.userProfile && props.userProfile.organization) {
            const snackbar = getSystemMessage(props.userProfile.organization);
            setSnackbarContent(snackbar);
        }
    }, [props.userProfile, props.whiteLabel]);

    useEffect(() => {
        if (!userProfile && localStorage.getItem('primaryToken') || !userProfile && localStorage.getItem('api')) {
            props.getProfile();
        }
    }, []);

    useEffect(() => {
        // If primary_token was returned, pass the boolean value to MenuBar
        let primaryCookie = localStorage.getItem('primaryToken');
        if (primaryCookie) {
            const primaryToken = JSON.parse(localStorage.getItem('primaryToken')!);
            if (primaryToken.grant_type === 'primary_account') {
                setAgentSelect(true);
            }
        }
    }, []);

    // populate the user and custom_properties sections for ONBOARDFLOW service
    useEffect(() => {
        if (userProfile) {
            const {organization} = userProfile;

            if (!("onboardFlowSettings" in (window as any))) {
                (window as any).onboardFlowSettings = [];
            }

            (window as any).onboardFlowSettings["user"] = {
                "id": organization.id,
                "customer_id": organization.customerId,
            }
        }
    }, [userProfile]);

    useEffect(() => {
        if (props.userProfile && (!props.userProfile.organization.isActive || !props.userProfile.organization.subscriptionIsActive)) {
            props.setServiceIsDisabledFlag(true);
        } else {
            props.setServiceIsDisabledFlag(false);
        }
    }, [userProfile]);


    return (
        <>
            {
                userProfile && snackbarContent
            }
            {userProfile &&
                <Box
                    className="content-container"
                >
                    <MenuBar agentSelect={agentSelect}/>
                    <Box className='App-content'>
                        <NavTabs/>
                        {/* A custom child element for the Account component is created so that a specific prop can be passed to it */}
                        <>
                            {React.Children.map(children, child => {
                                if (React.isValidElement(child) && child.type === Account) {
                                    return React.cloneElement(child as React.ReactElement<PrimaryAccount>, { isPrimaryAccount: agentSelect });
                                }
                                return child;
                            })}
                        </>
                    </Box>
                </Box>
            }
            <Loading/>
            {isDisabledService && userProfile || userProfile && (!userProfile.organization.isActive || !userProfile.organization.subscriptionIsActive) ?
                <Redirect to={`/org${userProfile.organizationId}/account/subscription`}/> :
                null}
            {
                userProfile && !verifying.isVerified &&
                <VerifyingPopup
                    open={!userProfile!.organization.isVerified}
                    email={userProfile!.login}
                    verifying={verifying}
                    handleConfirm={props.confirmEmailVerification}
                    handleResend={props.resendOTP}
                    handleClose={props.setVerifying}
                    redirect={props.redirect}
                    handleReenterSignupEmail={props.reenterSignupEmail}
                    handleReenteringFlag={props.setReenteringFlag}
                />
            }
            {
                userProfile && !agentSelect && userProfile.organization.subscriptionStatus === SubscriptionStatus.Trial && userProfile.organization.trialDaysLeft < 0 &&
                <TrialExpiredPopup />
            }
        </>
    )
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    return {
        whiteLabel: sharedSelectors.whiteLabelSelector(state),
        userProfile: sharedSelectors.profileSelector(state),
        isDisabledService: sharedSelectors.isDisabledServiceSelector(state),
        verifying: sharedSelectors.verifyingFlagSelector(state),
    }
}

const mapDispatchToProps = {
    getProfile,
    redirect,
    setServiceIsDisabledFlag,
    confirmEmailVerification,
    resendOTP,
    setVerifying,
    reenterSignupEmail,
    setReenteringFlag
}

const AppWrapperContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(AppWrapper);

export default AppWrapperContainer;
